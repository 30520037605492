import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import AspOfferDetailsContainer from 'presentation/features/offer/asp/details/container';
import { EAspOfferDetailsTab } from 'presentation/features/offer/asp/details/utils';
import { EAspOfferUrlParam } from 'presentation/features/offer/asp/utils';
import ScreenLayout from 'presentation/layouts/screen';
import { useLocation, useParams } from 'react-router';

const AspOfferScreen = () => {
  const location = useLocation();

  const { id } = useParams<{ id: UUID }>();
  const tab = (new URLSearchParams(location.search).get(EAspOfferUrlParam.Tab) ??
    EAspOfferDetailsTab.Description) as EAspOfferDetailsTab;

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <AspOfferDetailsContainer
            guid={guid}
            id={id}
            tab={tab}
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default AspOfferScreen;
