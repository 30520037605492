import store from 'data/store/store';
import { CorpOfferShort } from 'domain/model/corpOffer';
import { ECorpOfferSortType, EOfferListType, EOfferStatus, EOfferType } from 'domain/model/enums';
import { Category } from 'domain/model/nsi';
import { useWebAnalytics } from 'presentation/features/webAnalytics';
import { PaginationSize } from 'presentation/types';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getOffersListQueryParams } from '../../../utils';
import { getCorpOffersSearchRoute } from '../../routes';
import { corpOffersDefaultParams } from '../../utils';
import { corpOfferListSelector } from '../store/selectors';
import {
  corpOffersSetArgs,
  corpOffersSetIsNewFetching,
  corpOffersSetIsNewSearchFetching,
  corpOffersSetPage,
  corpOffersSetPageSize,
  corpOffersSetSort,
  corpOffersStartSession,
} from '../store/slice';

type UseCorpOfferListHandlersProps = {
  readonly guid: UUID;
  readonly name: Nullable<string>;
  readonly categoryId: Nullable<UUID>;
  readonly partnerId: Nullable<UUID>;
};

export type UseCorpOfferList = {
  readonly onChangeSort: (sort: ECorpOfferSortType[]) => void;
  readonly onChangePage: (type: EOfferListType, newPage: number) => void;
  readonly onChangePageSize: (type: EOfferListType, newPageSize: PaginationSize) => void;
  readonly onChangeCategory: (category: Category) => void;
  readonly onReturnToTopCategory: (id: Nullable<UUID>) => void;
  readonly onShowCard: (corpOffer: CorpOfferShort) => void;
};

const emptyParams = corpOffersDefaultParams;

const useCorpOfferListHandlers = (props: UseCorpOfferListHandlersProps): UseCorpOfferList => {
  const { guid, name, categoryId, partnerId } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const { webAnalytics } = useWebAnalytics();

  const getCurrentState = useCallback(() => {
    return corpOfferListSelector(store.getState());
  }, []);

  const onChangeSort = useCallback<UseCorpOfferList['onChangeSort']>(
    sort => {
      dispatch(corpOffersSetIsNewFetching(false));
      dispatch(corpOffersSetIsNewSearchFetching(false));
      dispatch(corpOffersSetSort({ type: EOfferListType.Common, sort }));
      dispatch(corpOffersSetSort({ type: EOfferListType.Upcoming, sort }));
    },
    [dispatch]
  );

  const onChangePageSize = useCallback<UseCorpOfferList['onChangePageSize']>(
    (type, pageSize) => {
      dispatch(corpOffersSetIsNewFetching(false));
      dispatch(corpOffersSetIsNewSearchFetching(false));
      dispatch(corpOffersSetPageSize({ type, pageSize }));
    },
    [dispatch]
  );

  const onChangePage = useCallback<UseCorpOfferList['onChangePage']>(
    (type, page) => {
      dispatch(corpOffersSetIsNewFetching(false));
      dispatch(corpOffersSetIsNewSearchFetching(false));
      dispatch(corpOffersSetPage({ type, page }));
    },
    [dispatch]
  );

  const onChangeCategory = useCallback<UseCorpOfferList['onChangeCategory']>(
    category => {
      dispatch(corpOffersSetIsNewFetching(true));
      dispatch(corpOffersSetIsNewSearchFetching(false));
      history.push(getCorpOffersSearchRoute({ name, categoryId: category.id, guid, partnerId }));
    },
    [dispatch, history, name, guid, partnerId]
  );

  const onReturnToTopCategory = useCallback<UseCorpOfferList['onReturnToTopCategory']>(
    id => {
      dispatch(corpOffersSetIsNewFetching(true));
      dispatch(corpOffersSetIsNewSearchFetching(false));
      history.push(getCorpOffersSearchRoute({ name, categoryId: id, guid, partnerId: partnerId }));
    },
    [dispatch, history, guid, name, partnerId]
  );

  const onShowCard = useCallback<UseCorpOfferList['onShowCard']>(
    corpOffer => {
      webAnalytics.offerShow(corpOffer.id);
    },
    [webAnalytics]
  );

  useEffect(() => {
    const state = getCurrentState();
    const currentGuid = state.guid;
    const currentName = state.common.search?.name;
    const currentCategory = state.common.search?.categories?.[0];
    const currentPartnerId = state.common.search?.partnerId;

    dispatch(corpOffersStartSession({ guid }));

    const isGuidChanged = currentGuid !== guid;
    const isNameChanged = (currentName || null) !== (name || null);
    const isCategoryChanged = (currentCategory || null) !== (categoryId || null);
    const isPartnerIdChanged = (currentPartnerId || null) !== (partnerId || null);

    let commonArgs = state.common;
    let upcomingArgs = state.upcoming;

    if (isGuidChanged) {
      commonArgs = emptyParams;
      upcomingArgs = emptyParams;
    }

    dispatch(
      corpOffersSetArgs({
        type: EOfferListType.Common,
        args: getOffersListQueryParams(
          {
            name,
            categories: categoryId ? [categoryId] : null,
            partnerId,
            sort: commonArgs.sort,
            page: commonArgs.page,
            pageSize: commonArgs.pageSize,
          },
          EOfferType.Corp
        ),
      })
    );
    dispatch(
      corpOffersSetArgs({
        type: EOfferListType.Upcoming,
        args: getOffersListQueryParams(
          {
            name,
            categories: categoryId ? [categoryId] : null,
            partnerId,
            sort: upcomingArgs.sort,
            page: upcomingArgs.page,
            pageSize: upcomingArgs.pageSize,
            statuses: [EOfferStatus.Upcoming],
          },
          EOfferType.Corp
        ),
      })
    );

    if (isGuidChanged || isCategoryChanged || isPartnerIdChanged) {
      dispatch(corpOffersSetIsNewFetching(true));
    } else {
      dispatch(corpOffersSetIsNewFetching(false));
    }

    if (isGuidChanged || isNameChanged) {
      dispatch(corpOffersSetIsNewSearchFetching(true));
    } else {
      dispatch(corpOffersSetIsNewSearchFetching(false));
    }
  }, [dispatch, categoryId, guid, name, getCurrentState, partnerId]);

  return {
    onChangeSort,
    onChangePage,
    onChangePageSize,
    onChangeCategory,
    onReturnToTopCategory,
    onShowCard,
  };
};

export default useCorpOfferListHandlers;
