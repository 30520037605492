import { useGetCmsPartnerPageQuery } from 'data/api/cms';
import {
  PartnerOfferListRequest,
  useGetPartnerAspOfferListQuery,
  useGetPartnerBookingOfferListQuery,
  useGetPartnerCorpOfferListQuery,
  useGetPartnerDeskQuery,
  useGetPartnerProductOfferListQuery,
  useGetPartnerTradeOfferListQuery,
} from 'data/api/partner';
import ErrorHandler from 'data/network/errorHandler';
import { EOfferStatus } from 'domain/model/enums';
import { PartnerDesk, PartnerWindow } from 'domain/model/partner';
import { useEffect, useMemo } from 'react';
import useTechConfig from '../../../../hooks/useTechConfig';
import { EAppFeature } from '../../../../types';

import { EPartnerDeskDetailsTab, getPartnerOffersListQueryParams } from '../utils';

export type UseGetPartnerDeskDataParams = {
  readonly guid: UUID;
  readonly id: UUID;
  readonly categoryId?: Nullable<UUID>;
  readonly tab: Nullable<EPartnerDeskDetailsTab>;
  readonly isCorpUser: boolean;
  readonly bonusUnavailableReason?: Nullable<string>;
};

export type UseGetPartnerDeskDataPayload = {
  readonly isFetching: boolean;
  readonly partnerDesk: Nullable<PartnerDesk>;
  readonly partnerWindow: Nullable<PartnerWindow>;
  readonly tradeOffersCount: number;
  readonly aspOffersCount: number;
  readonly corpOffersCount: number;
  readonly productOffersCount: number;
  readonly bookingOffersCount: number;
  readonly partnerWindowContainersCount: number;
};

type UseGetPartnerDeskDataHook = (params: UseGetPartnerDeskDataParams) => UseGetPartnerDeskDataPayload;

const statuses: EOfferStatus[] = [EOfferStatus.Active, EOfferStatus.Upcoming, EOfferStatus.OutOfStock];

export const useGetPartnerDeskData: UseGetPartnerDeskDataHook = props => {
  const { guid, id, categoryId, tab, isCorpUser, bonusUnavailableReason } = props;
  const { hasFeature } = useTechConfig();

  const offersCountQueryParams = useMemo<PartnerOfferListRequest<any>>(
    () =>
      getPartnerOffersListQueryParams({
        partnerId: id,
        sort: [],
        statuses,
        page: 1,
        pageSize: 1,
      }),
    [id]
  );

  const {
    data: partnerDesk = null,
    isFetching: partnerDeskIsFetching,
    error: partnerDeskFetchError,
  } = useGetPartnerDeskQuery(id);

  const {
    data: partnerWindow = null,
    isFetching: partnerWindowIsFetching,
    error: partnerWindowFetchError,
  } = useGetCmsPartnerPageQuery({ guid, id }, { skip: !hasFeature(EAppFeature.PartnerWindow) });

  const {
    data: tradeOffers,
    isFetching: tradeOffersIsFetching,
    error: tradeOffersFetchError,
    refetch: tradeOffersRefetch,
  } = useGetPartnerTradeOfferListQuery(offersCountQueryParams, { skip: !hasFeature(EAppFeature.Trade) });

  const {
    data: aspOffers,
    isFetching: aspOffersIsFetching,
    error: aspOffersFetchError,
    refetch: aspOffersRefetch,
  } = useGetPartnerAspOfferListQuery(offersCountQueryParams, { skip: !hasFeature(EAppFeature.Asp) });

  const {
    data: corpOffers,
    isFetching: corpOffersIsFetching,
    error: corpOffersFetchError,
    refetch: corpOffersRefetch,
  } = useGetPartnerCorpOfferListQuery(offersCountQueryParams, { skip: !isCorpUser || !!bonusUnavailableReason });

  const {
    data: productOffers,
    isFetching: productOffersIsFetching,
    error: productOffersFetchError,
  } = useGetPartnerProductOfferListQuery(offersCountQueryParams, { skip: !hasFeature(EAppFeature.Product) });

  const {
    data: bookingOffers,
    isFetching: bookingOffersIsFetching,
    error: bookingOffersFetchError,
    refetch: bookingOffersRefetch,
  } = useGetPartnerBookingOfferListQuery(offersCountQueryParams, { skip: !hasFeature(EAppFeature.Booking) });

  const isFetching =
    partnerDeskIsFetching ||
    partnerWindowIsFetching ||
    (tradeOffersIsFetching && !tradeOffers) ||
    (aspOffersIsFetching && !aspOffers) ||
    (corpOffersIsFetching && !corpOffers) ||
    (bookingOffersIsFetching && !bookingOffers) ||
    productOffersIsFetching;

  const tradeOffersCount = tradeOffers?.totalCount ?? 0;
  const aspOffersCount = aspOffers?.totalCount ?? 0;
  const corpOffersCount = corpOffers?.totalCount ?? 0;
  const productOffersCount = productOffers?.totalCount ?? 0;
  const bookingOffersCount = bookingOffers?.totalCount ?? 0;
  const partnerWindowContainersCount = partnerWindow?.length ?? 0;

  useEffect(() => {
    if (partnerDeskFetchError) {
      ErrorHandler.handleHttpError(partnerDeskFetchError);
    }
    if (partnerWindowFetchError) {
      ErrorHandler.handleHttpError(partnerWindowFetchError);
    }
    if (tradeOffersFetchError) {
      ErrorHandler.handleHttpError(tradeOffersFetchError);
    }
    if (aspOffersFetchError) {
      ErrorHandler.handleHttpError(aspOffersFetchError);
    }
    if (corpOffersFetchError) {
      ErrorHandler.handleHttpError(corpOffersFetchError);
    }
    if (productOffersFetchError) {
      ErrorHandler.handleHttpError(corpOffersFetchError);
    }
    if (bookingOffersFetchError) {
      ErrorHandler.handleHttpError(bookingOffersFetchError);
    }
  }, [
    partnerDeskFetchError,
    partnerWindowFetchError,
    tradeOffersFetchError,
    aspOffersFetchError,
    corpOffersFetchError,
    productOffersFetchError,
    bookingOffersFetchError,
  ]);

  const isCorpOffersRefetchAllowed = isCorpUser && !bonusUnavailableReason;
  const isAspOffersRefetchAllowed = isCorpUser;

  useEffect(() => {
    tradeOffersRefetch();
    isAspOffersRefetchAllowed && aspOffersRefetch();
    isCorpOffersRefetchAllowed && corpOffersRefetch();
    bookingOffersRefetch();
  }, [
    categoryId,
    tab,
    tradeOffersRefetch,
    aspOffersRefetch,
    corpOffersRefetch,
    bookingOffersRefetch,
    isCorpOffersRefetchAllowed,
    isAspOffersRefetchAllowed,
  ]);

  return {
    isFetching,
    corpOffersCount,
    tradeOffersCount,
    aspOffersCount,
    productOffersCount,
    bookingOffersCount,
    partnerWindowContainersCount,
    partnerDesk,
    partnerWindow,
  };
};
