import { Link as MUILink } from '@mui/material';
import { CorpOfferShort } from 'domain/model/corpOffer';
import OfferListItem, { OfferListItemProps } from 'presentation/features/offer/components/listItem';
import CorpOfferListItemInfo from 'presentation/features/offer/corp/components/listItem/info';
import { forwardRef, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { getCorpOfferDetailsRoute } from '../../routes';
import CorpOfferListItemPrice from './price';

export type CorpOfferListItemProps = {
  readonly offer: Pick<CorpOfferShort, 'id' | 'name' | 'images' | 'partner' | 'price' | 'originalPrice'>;
  readonly favorite?: ReactNode;
  readonly direction?: OfferListItemProps['direction'];
};

const CorpOfferListItem = forwardRef<any, CorpOfferListItemProps>((props, ref) => {
  const { offer, favorite, direction } = props;
  const { id, images, name } = offer;

  return (
    <MUILink
      ref={ref}
      component={Link}
      underline='none'
      to={getCorpOfferDetailsRoute({ id })}
    >
      <OfferListItem
        images={images}
        header={
          <CorpOfferListItemPrice
            originalPrice={offer.originalPrice}
            price={offer.price}
          />
        }
        description={name}
        favorite={favorite}
        info={<CorpOfferListItemInfo {...offer} />}
        direction={direction}
      />
    </MUILink>
  );
});

CorpOfferListItem.displayName = 'CorpOfferListItem';

export default CorpOfferListItem;
