import { ETradeOfferDetailsTab } from 'presentation/features/offer/trade/details/utils';
import { useHistory } from 'react-router';
import { getTradeOfferDetailsRoute } from '../../routes';
import useTradeOfferDetailsData from '../hooks/useTradeOfferDetailsData';
import useTradeOfferPartnerDeskData from '../hooks/useTradeOfferPartnerDeskData';
import TradeOfferTabs from '../tabs';

type TradeOfferDetailsTabsAdapterProps = {
  readonly id: UUID;
  readonly guid: UUID;
  readonly tab: ETradeOfferDetailsTab;
};

const TradeOfferDetailsTabsAdapter = ({ tab, id, guid }: TradeOfferDetailsTabsAdapterProps) => {
  const history = useHistory();

  const { tradeOffer } = useTradeOfferDetailsData(id);

  const { partnerDesk } = useTradeOfferPartnerDeskData(id);
  const onChangeTab = (event: React.SyntheticEvent, newTab: ETradeOfferDetailsTab) => {
    history.replace(getTradeOfferDetailsRoute({ id, tab: newTab, guid }));
  };

  if (!tradeOffer || !partnerDesk) {
    return null;
  }

  return (
    <TradeOfferTabs
      tab={tab}
      tradeOffer={tradeOffer}
      partnerDesk={partnerDesk}
      onChange={onChangeTab}
    />
  );
};

export default TradeOfferDetailsTabsAdapter;
