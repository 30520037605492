import { AspOffer } from 'domain/model/aspOffer';
import { Moment } from 'moment-timezone';
import React from 'react';
import { ActivateOfferResponse } from '../../../../../../data/api/user';
import { OfferActivationState } from '../../../hooks/useOfferActivations';

export type AspOfferClaimContextType = {
  readonly aspOffer: Nullable<AspOffer>;
  readonly partnerLink: Nullable<string>;
  readonly endDate: Nullable<Moment>;
  readonly activation: OfferActivationState;
  readonly guid: UUID;
  readonly isFetching: boolean;
  readonly isSubscriptionEnabled: boolean;
  readonly isSubscriptionFetching: boolean;
  readonly isSubscriptionSupported: boolean;
  readonly isReceiptDateSupported: boolean;
  readonly isActivationAvailable: boolean;
  readonly isActivationReceivedNow: boolean;
  readonly isTimerActive: boolean;
  readonly stopTimer: () => void;
  readonly onSubscribe: () => void;
  readonly onUnSubscribe: () => void;
  readonly onPartnerButtonClick: () => void;
  readonly onFillProfile: () => void;
  readonly onTakeActivation: (result: ActivateOfferResponse) => void;
  readonly onActivate: () => Promise<Nullable<ActivateOfferResponse>>;
  readonly onCopy: () => void;
  readonly isTaked: boolean;
  readonly needCopy: boolean;
  readonly isTaking: boolean;
  readonly onNeedCopy: (value: boolean) => void;
};

const AspOfferClaimContext = React.createContext<AspOfferClaimContextType>(null as unknown as AspOfferClaimContextType);

export default AspOfferClaimContext;
