import React from 'react';

export type AspOfferClaimDialogsContextType = {
  readonly openUnavailableDialog: () => void;
  readonly openHelpDialog: () => void;
  readonly openNdflDialog: () => void;
  readonly onActivate: () => void;
  readonly onShowAllOffers: () => void;
};

const AspOfferClaimDialogsContext = React.createContext<AspOfferClaimDialogsContextType>(
  null as unknown as AspOfferClaimDialogsContextType
);

export default AspOfferClaimDialogsContext;
