import { ClaimWrapper, InnerWrapper, SkirtWrapper } from './controls';
import React from 'react';

type OfferClaimLayoutProps = {
  readonly content: Nullable<React.JSX.Element>;
  readonly skirt?: Nullable<React.JSX.Element>;
};

const OfferClaimLayout = React.forwardRef(({ content, skirt }: OfferClaimLayoutProps, ref: any) => {
  return (
    <ClaimWrapper ref={ref}>
      <InnerWrapper>
        {content}
        {skirt && <SkirtWrapper>{skirt}</SkirtWrapper>}
      </InnerWrapper>
    </ClaimWrapper>
  );
});

export default OfferClaimLayout;
