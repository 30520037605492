import { PartnerOfferListRequest, useGetPartnerBookingOfferListQuery } from 'data/api/partner';
import ErrorHandler from 'data/network/errorHandler';
import { EBookingOfferSortType } from 'domain/model/enums';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import { partnerDeskDetailsBookingOffersIsNewFetchingSelector } from 'presentation/features/partnerDesk/details/store/selectors';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../../auth/provider/useAuth';
import OfferListItemContainer from '../../../../offer/listItem/container';

type PartnerDeskBookingOffersDataContainerProps = {
  readonly guid: UUID;
  readonly args: PartnerOfferListRequest<EBookingOfferSortType>;
  readonly header?: React.ReactNode;
  readonly onChangePage: (newPage: number) => void;
};

const PartnerDeskBookingOffersDataContainer = (props: PartnerDeskBookingOffersDataContainerProps) => {
  const { args, guid, header, onChangePage } = props;

  const { isAuthenticated } = useAuth();
  const isNewFetching = useSelector(partnerDeskDetailsBookingOffersIsNewFetchingSelector);
  const { page, partnerId } = args;

  const { data, error } = useGetPartnerBookingOfferListQuery(args, {
    refetchOnMountOrArgChange: true,
  });

  const bookingOffers = data?.data;
  const totalCount = data?.totalCount ?? 0;

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  const hasOffers = !!bookingOffers && bookingOffers.length > 0;

  if (!hasOffers || isNewFetching) {
    return null;
  }

  return (
    <div>
      {header}
      <div>
        <CardPageableList
          totalCount={totalCount}
          onLoadMore={() => onChangePage(page + 1)}
        >
          {bookingOffers.map(bookingOffer => (
            <OfferListItemContainer
              key={bookingOffer.id}
              offer={bookingOffer}
              favorite={
                isAuthenticated && {
                  guid,
                  partnerId,
                }
              }
            />
          ))}
        </CardPageableList>
      </div>
    </div>
  );
};

export default PartnerDeskBookingOffersDataContainer;
