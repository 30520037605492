import CommonRenderManager from 'presentation/components/common/renderManager/container';
import RenderManagerErrorProvider from 'presentation/components/common/renderManager/error/provider';
import TradeOfferDetailsLoaderAdapter from 'presentation/features/offer/trade/details/adapters/loader';
import TradeOfferDetailStub from 'presentation/features/offer/trade/details/adapters/stub';
import OfferDetailsLayout from '../../../../layouts/offerDetail';
import TradeOfferBreadcrumbAdapter from './adapters/breadcrumb';
import TradeOfferCarouselAdapter from './adapters/carousel';
import TradeOfferCloseAdapter from './adapters/close';
import TradeOfferConditionsAdapter from './adapters/condition';
import TradeOfferDetailsFavoriteAdapter from './adapters/favorite';
import TradeOfferPartnerInfoAdapter from './adapters/partnerInfo';
import TradeOfferSidebarAdapter from './adapters/sidebar';
import SmartPhoneFixedBarAdapter from './adapters/smartPhoneFixedBar';
import TradeOfferDetailsTabsAdapter from './adapters/tabs';
import TradeOfferDetailsTitleAdapter from './adapters/title';
import { TradeOfferDetailContext } from './context';
import useTradeOfferDetails from './hooks/useTradeOfferDetails';
import useTradeOfferDetailsLoadingStatus from './hooks/useTradeOfferDetailsLoadingStatus';
import { ETradeOfferDetailsTab } from './utils';

type TradeOfferDetailsContainerProps = {
  readonly id: UUID;
  readonly guid: UUID;
  readonly tab: ETradeOfferDetailsTab;
};

const Layout = OfferDetailsLayout;

const TradeOfferDetailsContainer = ({ id, tab, guid }: TradeOfferDetailsContainerProps) => {
  const handlers = useTradeOfferDetails({ id, guid });

  return (
    <TradeOfferDetailContext.Provider value={handlers}>
      <RenderManagerErrorProvider>
        <CommonRenderManager
          loader={<TradeOfferDetailsLoaderAdapter id={id} />}
          stub={TradeOfferDetailStub}
        >
          <Layout
            id={id}
            favorite={
              <TradeOfferDetailsFavoriteAdapter
                id={id}
                guid={guid}
              />
            }
            description={<TradeOfferConditionsAdapter id={id} />}
            sidebar={
              <TradeOfferSidebarAdapter
                guid={guid}
                id={id}
              />
            }
            smartphoneFixedBar={
              <SmartPhoneFixedBarAdapter
                guid={guid}
                id={id}
              />
            }
            tabs={
              <TradeOfferDetailsTabsAdapter
                guid={guid}
                tab={tab}
                id={id}
              />
            }
            partnerInfo={<TradeOfferPartnerInfoAdapter id={id} />}
            title={<TradeOfferDetailsTitleAdapter id={id} />}
            breadcrumb={<TradeOfferBreadcrumbAdapter id={id} />}
            image={<TradeOfferCarouselAdapter id={id} />}
            close={<TradeOfferCloseAdapter />}
            useLoading={useTradeOfferDetailsLoadingStatus}
          />
        </CommonRenderManager>
      </RenderManagerErrorProvider>
    </TradeOfferDetailContext.Provider>
  );
};

export default TradeOfferDetailsContainer;
