import { useWebAnalytics } from 'presentation/features/webAnalytics';
import useHistoryExtensions from 'presentation/hooks/useHistoryExtensions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAspOffersListRoute } from '../../routes';
import { aspOfferDetailsStartSession } from '../store/slice';

export type UseAspOfferDetails = {
  readonly onBack: () => void;
};

export type UseAspOfferDetailsProps = {
  readonly guid: UUID;
  readonly id: UUID;
};

const useAspOfferDetails = ({ id, guid }: UseAspOfferDetailsProps): UseAspOfferDetails => {
  const { webAnalytics } = useWebAnalytics();
  const dispatch = useDispatch();
  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  useEffect(() => {
    webAnalytics.offerView(id);
  }, [webAnalytics, id]);

  useEffect(() => {
    dispatch(aspOfferDetailsStartSession({ guid }));
  }, [dispatch, guid]);

  const onBack = () => {
    gotoPrevIndependentLocation(getAspOffersListRoute());
  };

  return {
    onBack,
  };
};

export default useAspOfferDetails;
