import { FC } from 'react';
import UserBalanceExpire from '../../../..//components/balanceExpire';
import { BalanceSlotExpire } from '../../layout/controls';
import { UserFinanceBalanceSlotAdapterProps } from './types';

export const UserFinanceBalanceSlotExpireAdapter: FC<UserFinanceBalanceSlotAdapterProps> = ({ balance }) => {
  const info = balance.pointsExpirationInfo;

  return (
    info?.dateExpiration &&
    info.sum !== null && (
      <BalanceSlotExpire>
        <UserBalanceExpire
          value={info.sum}
          type={balance.type}
          expireDate={info.dateExpiration}
        />
      </BalanceSlotExpire>
    )
  );
};
