import { combineReducers } from '@reduxjs/toolkit';
import list, { AspOfferListState } from './list/store/slice';
import details, { AspOfferDetailsState } from './details/store/slice';

type AspOfferState = {
  readonly list: AspOfferListState;
  readonly details: AspOfferDetailsState;
};

export default combineReducers<AspOfferState>({
  list,
  details,
});
