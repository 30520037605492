import { useGetAspOfferDetailsQuery } from 'data/api/aspOffer';
import { EBalanceType, EOfferType, OfferPromotionType } from 'domain/model/enums';
import moment from 'moment-timezone';
import { getUserProfileRoute } from 'presentation/features/user/entry';
import { normalizedLink } from 'presentation/utils';
import React, { FCC, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from '../../../../auth/provider/useAuth';
import useOfferActivations from '../../../hooks/useOfferActivations';
import useOfferSubscription from '../../../hooks/useOfferSubscription';
import useTakeOfferActivations from '../../../hooks/useTakeOfferActivations';
import { isOfferReceiptDateSupportedByPromotionType } from '../../../utils';
import { getAspOfferCodeAssignDurationInSeconds } from '../../utils';
import AspOfferClaimContext, { AspOfferClaimContextType } from './context';

type AspOfferClaimProviderProps = {
  readonly id: UUID;
  readonly guid: UUID;
  readonly promotionType: OfferPromotionType;
  readonly isActivationAllowed?: boolean;
  readonly onPartnerButtonClick: () => void;
};

const AspOfferClaimProvider: FCC<AspOfferClaimProviderProps> = props => {
  const { children, id, promotionType, onPartnerButtonClick, guid, isActivationAllowed = true } = props;

  const history = useHistory();

  const [timerActive, setTimerActive] = React.useState<boolean>(false);

  const { isAuthenticated, login } = useAuth();

  const { data: aspOffer = null, refetch: aspOfferRefetch } = useGetAspOfferDetailsQuery({ id });

  const activation = useOfferActivations({
    offerId: id,
    offerRefetch: aspOfferRefetch,
    balanceType: EBalanceType.ASP,
    isActivationAllowed,
  });

  const { lastActivation, activationError, isLoadActivationsFetching, lastReceivedActivation } = activation;

  const { analyzeOfferActivation, onTakeActivation, needCopy, isTaked, onNeedCopy, isTaking, onCopy } =
    useTakeOfferActivations({
      lastActivation,
    });

  const {
    isEnabled: isSubscriptionEnabled,
    isFetching: isSubscriptionFetching,
    isSupported: isSubscriptionSupported,
    onSubscribe,
    onUnSubscribe,
  } = useOfferSubscription({
    offer: aspOffer,
    offerType: EOfferType.Trade,
    promotionType,
  });

  const isReceiptDateSupported = isOfferReceiptDateSupportedByPromotionType(promotionType);

  const isActivationAvailable = activation.isActivationAvailable;
  const partnerLink = aspOffer?.partnerLink ? normalizedLink(aspOffer.partnerLink) : '';
  const endDate = aspOffer ? moment(aspOffer.endDate).endOf('day') : null;

  const isFetching = isLoadActivationsFetching;
  const isActivationReceivedNow = !!lastReceivedActivation;

  const onActivate = useCallback(async () => {
    if (!isAuthenticated) {
      login();
      return null;
    }

    if (!aspOffer) {
      return null;
    }
    analyzeOfferActivation(aspOffer);
    const result = await activation.activateOffer(aspOffer);
    if (result) {
      onTakeActivation(result);
    }

    return result;
  }, [isAuthenticated, aspOffer, analyzeOfferActivation, activation, login, onTakeActivation]);

  const stopTimer = useCallback(() => {
    setTimerActive(false);
  }, [setTimerActive]);

  const onFillProfile = useCallback(() => {
    history.push(getUserProfileRoute());
  }, [history]);

  useEffect(() => {
    const duration = lastActivation?.appointmentDate
      ? getAspOfferCodeAssignDurationInSeconds(lastActivation?.appointmentDate)
      : 0;
    setTimerActive(duration > 0);
  }, [lastActivation?.appointmentDate, activationError]);

  const value: AspOfferClaimContextType = {
    aspOffer,
    partnerLink,
    endDate,
    activation,
    isFetching,
    guid,
    isSubscriptionEnabled,
    isSubscriptionFetching,
    isSubscriptionSupported,
    isReceiptDateSupported,
    isActivationAvailable,
    isActivationReceivedNow,
    isTimerActive: timerActive,
    stopTimer,
    onTakeActivation,
    onActivate,
    onSubscribe,
    onUnSubscribe,
    onPartnerButtonClick,
    onFillProfile,
    onCopy,
    isTaked,
    needCopy,
    onNeedCopy,
    isTaking,
  };

  return <AspOfferClaimContext.Provider value={value}>{children}</AspOfferClaimContext.Provider>;
};

export default AspOfferClaimProvider;
